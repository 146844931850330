if (typeof window !== 'undefined') {

    class BufferPolyfill {
        private arr: Uint8Array;

        constructor(input?: string | ArrayBuffer | Uint8Array) {
            if (typeof input === 'string') {
                this.arr = new TextEncoder().encode(input);
            } else if (input instanceof ArrayBuffer) {
                this.arr = new Uint8Array(input);
            } else if (input instanceof Uint8Array) {
                this.arr = input;
            } else {
                this.arr = new Uint8Array(0);
            }
        }

        // Implement static allocUnsafe method
        static allocUnsafe(size: number): BufferPolyfill {
            return new BufferPolyfill(new Uint8Array(size));
        }

        // Implement static alloc method
        static alloc(size: number, fill?: number | string): BufferPolyfill {
            const buffer = new BufferPolyfill(new Uint8Array(size));
            if (fill !== undefined) {
                if (typeof fill === 'number') {
                    buffer.arr.fill(fill);
                } else if (typeof fill === 'string') {
                    buffer.arr.set(new TextEncoder().encode(fill.slice(0, size)));
                }
            }
            return buffer;
        }

        // Method to create a new instance from various inputs
        static from(input: string | ArrayBuffer | Uint8Array): BufferPolyfill {
            return new BufferPolyfill(input);
        }

        toString(encoding: string = 'utf8'): string {
            if (encoding === 'hex') {
                return Array.from(this.arr)
                    .map(b => b.toString(16).padStart(2, '0'))
                    .join('');
            }
            return new TextDecoder().decode(this.arr);
        }

        // Byte length method to calculate the byte length of a string
        static byteLength(str: string, encoding?: string): number {
            if (encoding === 'hex') {
                return Math.floor(str.length / 2);
            }
            return new TextEncoder().encode(str).length;
        }

        // Length property
        get length(): number {
            return this.arr.length;
        }

        // Check if object is an instance of BufferPolyfill
        static isBuffer(obj: any): boolean {
            return obj instanceof BufferPolyfill;
        }

        // Slice method to return a section of the buffer
        slice(start?: number, end?: number): BufferPolyfill {
            return new BufferPolyfill(this.arr.slice(start, end));
        }
    }


    // Assign BufferPolyfill to the global scope
    (window as any).Buffer = BufferPolyfill;
}

